@import "../../sass/responsive.scss";

.container_instruccionGeo {
  // margin-top: 6%;
  text-align: -webkit-center;
  .text_1 {
    margin-top: 2.5em;

    font-family: Open Sans;
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;

    text-align: center;

    color: #ffffff;

    text-shadow: 0px 2px 1px #000000;
  }
  .text_2 {
    font-weight: bold;
    font-size: 28px;
    line-height: 23px;

    text-align: center;

    color: #ffffff;

    text-shadow: 0px 2px 1px #000000;
  }
  .icon-e {
    color: white;
    font-size: 100px;
  }

  img {
    width: 17%;
    margin-bottom: 2em;
  }

  .text_3 {
    font-weight: 600;
    font-size: 24px;
    line-height: 28px;
    /* or 117% */

    text-align: center;

    color: #ffffff;

    text-shadow: 0px 2px 1px #000000;
  }
  .text_4 {
    font-weight: 600;
    color: rgba(255, 255, 255, 0.8);
    font-size: 12px;

    padding: 0% 38% 0% 38%;
    @include respond(phone) {
      padding: 2% 4% 0% 4%;
    }
  }

  

  .btn_modify {
    border-radius: 6px;
    background: transparent;

    border: 1px solid #9b9a9ac4;
    color: #9b9a9ac4;
    width: 12em;
    padding: 2px;

    h1 {
      margin-top: 2px;
      font-weight: 700;
      font-size: 15px;
    }
  }

  .btn_continue {
    margin-top: 8%;
    border-radius: 10px;

    width: 13em;
    padding: 2px;
    font-size: 16px;

    cursor: pointer;

    background: linear-gradient(180deg, #0a6dc9ec, #021738c5);
    border: 1px solid #000000;
    color: white;
    @include respond(phone) {
      margin-top: 10%;
    }
    h1 {
      margin-top: 2px;
      font-weight: 700;
      font-size: 19px;
    }
  }

  .text_5 {
    margin-top: 3%;
    color: rgba(228, 227, 227, 0.541);
    font-size: 10px;
    font-weight: 600;
    display: grid;
    @include respond(phone) {
      margin-top: 8%;
    }
    
  }

  .text_6 {
    font-family: 'Open Sans';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 16px;


    color: rgba(230, 255, 77, 0.8);
  }
}
// ---------------css of Recolectverify2 and Recolectverify-----------------------
/* .instruccion-geo {
  .otp-inputs-code {
    display: inline-grid;
    .inputs-code {
      width: 45px !important;
      height: 60px;
      margin: 2px;
      border: none;
      background-color: #e0e0e030;
      color: white;
      border-radius: 10px;
      margin-bottom: 20px;

      @include respond(tab-land) {
        margin: 2px;
        width: 35px !important;
        height: 46px !important;
      }
    }
  }
} */
