@font-face {
  font-family: AvantGardeDemiBT;
  src: url(./fuentes/AvantGardeDemiBT.ttf);
}
@font-face {
  font-family: OpenSans-Regular;
  src: url(./fuentes/OpenSans-Regular.ttf);
}
@font-face {
  font-family: OpenSans-light;
  src: url(./fuentes/OPENSANS-LIGHT.TTF);
}
.terminosPage p {
  font-family: OpenSans-light;
}
.text-inicial h1 {
  font-size: 5vw;
}
@media (min-width: 770px) and (max-width: 3000px) {
  img.icon-message {
    width: 150px;
    margin-left: 0px;
    margin-top: 20px;
    margin-bottom: 20px;
    filter: invert(1);
  }
  /* .encuadre {
    width: 100%;

    background: "white";
    z-index: 10000;
    position: absolute;
    left: 14px;
    top: 88px;
    display: flex;
  } */
  .text-inicial h1 {
    font-size: 33px;
  }
}

@media only screen and (max-width: 769px) {
  img.icon-message {
    width: 145px;
    margin-left: 0px;
    filter: invert(1);
  }
  .text-inicial h1 {
    font-size: 24px;
  }
  .terms-conditions h1 {
    font-size: 24px;
  }
  .text-mobile > h1 {
    font-size: 24px !important;
  }
  .recuadro-general {
    display: none !important;
  }
  .text > h2 {
    font-size: 24px !important;
  }
  .encuadre {
    display: none !important;
  }
  .h2,
  h2 {
    font-size: 2rem;
    /* font-size: 24px !important; */
  }
  /* h1.AVGARDNI {
    font-size: 5vw !important;
  } */
}
.border-bottom-encuadre {
  bottom: 0;
  position: fixed;
}
.text-ready {
  font-size: 29px !important;
  /* padding-top: 20px; */
  padding: 10px;
}
h1 {
  font-size: 5vw;
  font-family: AvantGardeDemiBT;
}
h2,
.AVGARDNI {
  font-family: OpenSans-Regular;
}
h3 {
  font-family: AvantGardeDemiBT;
}
h1,
h2.AVGARDNI {
  font-size: 5vw;
  font-family: AvantGardeDemiBT;
}
h1,
h2.OpenSans-Regular {
  font-size: 5vw;
  font-family: OpenSans-Regular;
}
.App {
  text-align: center;
}

.terms-conditions div {
  font-family: "Open Sans Condensed", sans-serif;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.btn-circle.btn-xl {
  width: 80%;
  height: 100%;
  padding: 10px 16px;
  border-radius: 35px;
  font-size: 24px;
  line-height: 1.33;
}

.btn-circle {
  width: 80%;
  height: 100%;
  padding: 6px 0px;
  border-radius: 15px;
  text-align: center;
  font-size: 12px;
  line-height: 1.42857;
}

#video-stream {
  position: fixed;
  right: 0;
  top: 0;
  width: 100%;
  height: 100%;
}
/* shading a colored image */
.shaded {
  background: linear-gradient(rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6)),
    url(https://s3-us-west-2.amazonaws.com/s.cdpn.io/3/skyscrapers.jpg);
  background-size: cover;
}
.iconEntidad {
  filter: invert(1);
}
/*#webcam {
  position: fixed;
  right: 0;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;  
}*/
#AppFinal {
  position: fixed;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}
#App2 {
  position: fixed;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;

  overflow-x: hidden;
}
/* .content {
   background: #1d1e3b; 
  background: #440830; 
   background: rgb(32,32,47); 

   background: radial-gradient(circle, rgba(32,32,47,1) 7%, rgba(32,32,47,1) 29%, rgba(29,29,44,1) 47%, rgba(19,19,32,1) 71%, rgba(16,16,27,1) 93%);
   
} */
.icon-bvc {
  width: 300px;
}

.terms-conditions {
  color: #d9d9db;
}

button.btn.btn-default.deniet {
  color: gray;
  font-size: 25px;
}
.btn:hover {
  color: #d3dde6;
  text-decoration: none;
}
button.btn.btn-default.acept {
  font-size: 25px;
  color: #d3dde6;
  cursor: pointer;
}
.input-form {
  border: none;
  background-color: #e0e0e030;
  color: white;
  border-radius: 10px;
  margin-bottom: 20px;
  width: 211px;
  text-align: center;
}

input:focus {
  outline: none;
  cursor: pointer;
  border: solid 1px #565967;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

canvas.sound-wave {
  width: 100%;
  height: 50px;
}
#video-stream {
  position: fixed;
  bottom: 0;
  min-width: 100%;
  min-height: 100%;
}

.video {
  width: 100vw;
}
.content {
  position: absolute;
  top: 0;
  width: 100vw;
  min-height: 100%;
}

.child {
  position: absolute;
  top: 0;
}

.child-1 {
  left: 0;
}

.child-2 {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.child-3 {
  position: fixed; /* Sit on top of the page content */
  display: block; /* Hidden by default */
  width: 100%; /* Full width (cover the whole page) */
  height: 100%; /* Full height (cover the whole page) */
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0); /* Black background with opacity */
  z-index: 2; /* Specify a stack order in case you're using a different order for other elements */
  cursor: pointer; /* Add a pointer on hover */
}

.parent {
  position: relative;
}

#div-final {
  position: "fixed" !important;
  bottom: 100 !important;
}

@media only screen and (max-width: 950px) {
  #div-final {
    position: "static";
    bottom: 0;
  }
  .ecu {
    position: relative;
    left: -90px;
    animation: chbgfinal 9s infinite alternate;
  }
}

.border-box {
  border: 2px solid #d0bebe;
  height: 82%;
  color: white;
  width: 126px;
  background: #2f301924;
  border-radius: 4px;
  margin-right: 13px;
  text-align: center;
  border-radius: 8px;
  padding-bottom: 10px;
  font-size: 12px;
}
img.icon-arrow {
  width: 22px;
  filter: invert(1);
  transform: rotate(-90deg);
  position: absolute;
  left: 2px;
  top: 22px;
}
img.iconFold {
  width: 33px;
  margin: 14px;
  margin-left: 1px;
  position: absolute;
  top: 4px;
}
img.icon-arrow-right {
  width: 22px;
  filter: invert(1);
  transform: rotate(90deg);
  position: absolute;
  left: -11px;
}

.text-neural {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 4px;
}
.text-bio {
  font-size: 11px;
  margin-top: 8px;
  margin-bottom: 3px;
}
.text-geo {
  font-size: 11px;
  color: #fff;
  font-weight: 800;
}
.text-information {
  color: #fff;
  margin-bottom: 6px;
  text-transform: uppercase;
  line-height: 16px;
  font-weight: 500;
}
.table-description {
  width: 97%;
  position: fixed;
  bottom: 0;
  padding-bottom: 80px;
  z-index: 10;
  cursor: pointer;
}
.tap {
  width: 208px;
  background: #e4e4e4;
  height: 6px;
  padding-left: 7px;
  margin: 8px 5px 0 7px;
  border-radius: 1px;
}
div#video-stream {
  /* background: #1d1c1c; */
  /* background-image: url("./assets/back-img.jpeg"); */
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
#webcam {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.MyLoader_wrapper.css-79elbk {
  z-index: 4;
}
.MyLoader_overlay {
  background: rgba(0, 0, 0, 0);
}
.text-anteia {
  margin-bottom: 0;
  text-align: right;
  color: white;
  font-size: 11px;
  padding-right: 6px;
}
.child {
  position: initial !important;
  bottom: 0;
  text-align: right;
  font-size: 11px;
}
.text-box {
  font-size: 10px;
}
.block {
  height: 7px;
  width: 25px;
  float: right;
  margin: 5px;
  margin-right: 0;
  border: solid 1px #e0e0e0;
}

@media (max-width: 800px) {
  .table-description {
    padding-bottom: 0px;
  }
  .imagEntidad {
    width: 46%;
    top: -1px;
    position: absolute;
    left: 0px;
    filter: opacity(0.3);
  }
}
@media (min-width: 600px) {
  img.iconFold {
    width: 33px;
    margin: 14px;
    margin-left: 1px;
    position: absolute;
    top: -15px;
  }
}

@media (min-width: 1916px) {
  .table-description {
    padding-bottom: 47px;
  }
}
/* -------------------------------FILTER_BACKGROUND NOT USED--------------------------------------------- */
.filter {
  width: 100%;
  height: 100%;
  /* background-color: #440830;
  background: linear-gradient(360deg, #2778c442, #0066fe75); */
  /* background: linear-gradient(180deg,#2778c442,  #0066fe75);  */
  /* background: #27305d4f; */
  position: absolute;
  /* z-index: 2; */
}

.color-white {
  color: white;
}

img.icon-arrow {
  width: 22px;
  filter: invert(1);
  transform: rotate(-90deg);
  position: absolute;
  left: 2px;
  top: 22px;
}
img.iconFold {
  width: 33px;
  margin: 14px;
  margin-left: 1px;
  position: absolute;
  top: 4px;
}
img.icon-arrow-right {
  width: 22px;
  filter: invert(1);
  transform: rotate(90deg);
  position: absolute;
  left: -11px;
}

.text-neural {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 0;
  margin-top: 4px;
}
.text-bio {
  font-size: 11px;
  margin-top: 8px;
  margin-bottom: 3px;
}
.text-geo {
  font-size: 11px;
  color: #fff;
  font-weight: 800;
}
.text-information {
  color: #fff;
  margin-bottom: 6px;
  text-transform: uppercase;
  line-height: 16px;
  font-weight: 500;
}
.table-description {
  width: 97%;
  position: fixed;
  bottom: 0;
  padding-bottom: 80px;
  z-index: 10;
  cursor: pointer;
}

div#video-stream {
  /* background: #1d1c1c; */
  /* background-image: url("./assets/back-img.jpeg"); */
  background-size: cover;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
}
#webcam {
  position: fixed;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
}
.MyLoader_wrapper.css-79elbk {
  z-index: 4;
}
.text-anteia {
  margin-bottom: 0;
  text-align: right;
  color: white;
  font-size: 11px;
  padding-right: 6px;
}
.child {
  position: initial !important;
  bottom: 0;
  text-align: right;
  font-size: 11px;
}
.text-box {
  font-size: 10px;
}

@media (max-width: 990px) {
  .table-description {
    padding-bottom: 0px;
  }

  .imagEntidad {
    width: 46%;
    top: -1px;
    position: absolute;
    left: 0px;
    filter: opacity(0.3);
  }
  .table-description {
    height: 22%;
    overflow: overlay;
  }
}
@media (min-width: 600px) {
  img.iconFold {
    width: 33px;
    margin: 14px;
    margin-left: 1px;
    position: absolute;
    top: -15px;
  }
}

#canvasPuntitos {
  z-index: 100;
  justify-content: center;
  align-items: center;
  display: block;
  filter: drop-shadow(0px 0px 3px white);
  height: 100%;
  width: 100%;
  margin: auto;
  margin-top: 35px;
}

.inner-picture {
  z-index: -1;
  width: 80vw;
  height: 130vw;
  margin: auto;
  border: 3px solid #ffffff4f;
  border-radius: 20px;
  position: relative;
  top: 1vw;
  filter: drop-shadow(2px 4px 6px black);
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
}

.inner-picture-color {
  z-index: -1;
  width: 80vw;
  height: 130vw;
  /* height: 50vw; */
  margin: auto;
  border: 3px solid #ffffff4f;
  border-radius: 20px;
  position: relative;
  top: 1vw;
  /* filter: drop-shadow(2px 4px 6px black); */
  /* background-color: #00000038; */
  background-color: #000000a2;
  /* box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.8); */
}
/* -------------------border_face_video with image_face------------------------------ */
.inner-face {
  width: 97%;

  height: 97%;
  margin: auto;
  border: 1px solid #c4c4c44d;
  border-radius: 20px;
  position: relative;
  top: 1vw;
  filter: drop-shadow(2px 4px 6px black);
  text-align: -webkit-center;
}
.inner-txt-face {
  margin: 1% 0% 1% 0%;
  border-radius: 20px;
  border: 1px solid #c4c4c441;
  width: 94.5%;
  height: 15%;
}
.border-face {
  margin: 1% 0% 1% 0%;
  border-radius: 20px;
  border: 1px solid #c4c4c441;
  width: 94.5%;
  height: 70%;
}
.img-border-face {
  width: 99%;
  height: 99%;
}
.img-border-face-smile {
  margin-top: 7em;
  width: 240px;
  height: 240px;
}
.inner-txt2-face {
  justify-content: space-evenly;
  /* justify-content: center; */
  margin: 1% 0% 1% 0%;
  display: flex;
  color: white;
  border-radius: 20px;
  border: 1px solid #c4c4c441;
  width: 94.5%;
  /* height:15%; */
}
/* ------------landscape : rotación horizontal--------------------- */
@media (orientation: landscape) {
  .inner-picture {
    height: 50vw;
  }
}

.cont-picture {
  width: 100%;
  /* height: 100%; */
  justify-content: center;
  align-items: center;
  /* margin: auto; */
  position: absolute;
  display: flex;

  /* background-color: #0f4165; */
}

.cont-picture-liveness {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: absolute;
  display: flex;

  /* background-color: #0f4165; */
}

.cont-picture-color {
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  margin: auto;
  position: absolute;
  display: flex;
  /* background-image: url("./assets/back-img.jpeg"); */
  background-image: radial-gradient(#047bd6, #000e1a);
  background-size: cover;

  /* background-color: #0f4165; */
}

svg.MuiSvgIcon-root {
  display: flex;
  color: white;
}

@media (min-width: 1916px) {
  .table-description {
    padding-bottom: 12px;
  }
}

.css-79elbk {
  z-index: 14;
}
/* -------------------------------FILTER_BACKGROUND NOT USED--------------------------------------------- */
.filter {
  width: 100%;
  height: 100%;
  /* background-color: #440830;
  
  background: linear-gradient(360deg, #2778c442, #0066fe75); */
  /* background: linear-gradient(180deg,#2778c442,  #0066fe75);  */

  position: absolute;
  /* z-index: 2; */
}

.text-terms {
  font-size: 11px;
  text-align: right;
}
.color-white {
  color: white;
}
button,
input {
  text-align: center;
}

.recuadro-general {
  pointer-events: none;
  z-index: 100;
  height: 142%;
  position: absolute;
  justify-content: center;
  display: grid;
}
.conts-biometry {
  top: 0px;
  display: contents;
}
.conts-derciptions {
  position: fixed !important;
  bottom: 0;
  left: 0;
  height: 40px;
  margin-bottom: 30px;
}
.border-box-top {
  border: 2px solid #d0bebe;
  height: 81%;
  color: white;
  width: 126px;
  background: #2f301924;
  border-radius: 4px;
  margin-right: 13px;
  text-align: center;
  padding-left: 14px;
  padding-right: 10px;
  border-radius: 8px;
}
.font h6.font-d-p {
  font-size: 9px;
  position: absolute;
  left: 3px;
}
.iconVision {
  position: absolute;
  right: 65px;
  bottom: 0;
}

img.img-border {
  width: 160px;
  filter: opacity(0.5);
}

#deviceIdView {
  position: absolute;
  font-size: 12px;
  font-family: monospace;
  width: 304px;
  text-align: -webkit-right;
}
.cont-info-ip {
  position: absolute !important;
  top: 95px;
  left: -323px;
  filter: opacity(0.5);
}
.ip {
  color: #ffffff;
  position: absolute;
  top: 12px;
  left: -3px;
}
.videoFace {
  /* ---------------figure egg------------------- */
  /* width: 300px !important; */
  width: 80% !important;
  top: 20%;
  left: 10%;
  /* margin-bottom: 13em; */
  content-visibility: hidden;
  z-index: 100;
  position: absolute;
  /* margin-right: 3em; */
  height: 50% !important;
  /* height: 400px !important; */
  /* padding: 25%  0% 10% 0%; */
  border-radius: 60% / 50% 50% 72% 72%;
  box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.5);
  /* ------------------------------------------ */
  /*Mirror code*/
  transform: rotateY(180deg) !important;
  -webkit-transform: rotateY(180deg) !important; /* Safari and Chrome */
  -moz-transform: rotateY(180deg) !important; /* Firefox */
}
.videoFace-liveness {
  width: 90% !important ; 




   height: 90vh !important;    
  /* padding: 25%  0% 10% 0%; */

  /* ------------------------------------------ */
  /*Mirror code*/
}
.videoFaceMirror {
  transform: rotateY(180deg) !important;
  -webkit-transform: rotateY(180deg) !important; /* Safari and Chrome */
  -moz-transform: rotateY(180deg) !important; /* Firefox */
}

@media (max-width: 999px) {
  /* .cont-picture{
    display: none;
  } */
  .conts-derciptions {
    display: none;
  }
}
